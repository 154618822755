<template>
  <module
      ref="module"
      id="multipleAddAccount"
      titleIcon="fa fa plus"
      :title="$t('multipleaccountadd').toUpperCase()"
      :use-default-list="false"
    >
    <template slot="toolbar-global">
      <p-button v-if="!Constants.methods.isMobile()" type="default" v-on:click="prevPage()">
        <i slot="label" class="nc-icon nc-minimal-left"></i>
        {{ $t('back') }}
      </p-button>

<!--      <p-button class="ml-3" v-if="!Constants.methods.isMobile()" type="default" v-on:click="resetRows()">-->
<!--        <i slot="label" class="fa fa-refresh"></i>-->
<!--        {{ $t('clean_all') }}-->
<!--      </p-button>-->

    </template>
    <div slot="global">
      <!-- Modal CSV -->
      <modal :show.sync="modal_csv" :required="true" headerClasses="justify-content-center" >
        <div slot="header">
          <h4 class="title title-up"> {{$t('newImport')}} </h4>
        </div>

        <div slot="body" class="text-center" v-if="modal_csv_inprogress">
          <h2>{{ $t('loadingtxt') + ". " + this.$t('pleasewait') }}</h2>
          <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
        </div>
        <div slot="body" v-else class="col-md-12">
          <span class="row"> {{ $t('csv_import_instruction_01') }} </span>
          <span class="row ml-2"> {{ $t('csv_import_instruction_02') }} </span>
          <span class="row ml-2"> {{ $t('csv_import_instruction_03') }} </span>
          <span class="row ml-2"> {{ $t('csv_import_instruction_04') }} </span>
          <br>
          <div class="text-center">
            <p-button v-on:click="startDownload" type="info"> DOWNLOAD CSV Model  </p-button>
          </div>

        </div>

        <template slot="footer">
          <div class="left-side">
            <p-button type="success" v-on:click="importCSV" link >Upload</p-button>
            <input
                ref="fileInput"
                type="file"
                style="display: none;"
                @change="fileHandler"
                accept=".csv"
              />
          </div>
          <div class="divider"></div>
          <div class="right-side">
            <p-button type="default" link v-on:click="modal_csv = false"> ok </p-button>
          </div>
        </template>
      </modal>

      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div v-else class="row">

        <div class="card col-md-12">
          <div class="card-header">
          </div>
          <div class="card-body">
            <table class="col-md-12">
              <tr v-for="(r, index) in rows">
                <td class="text-center mobile-fix">
                  <label style="margin: 0px"> &nbsp; </label>
                  <div>
                    <p-button
                      class="btn btn-danger"
                      v-on:click="removeRow(index)"
                      v-if="rows.length > 1">
                      <i class="nc-icon nc-simple-remove"></i>
                    </p-button>
                  </div>
                </td>
                <td class="mobile-fix">
                  <label>{{ $t('helper_accbroker') }}</label>
                  <div v-if="r.broker == 'Other'" class="row">
                    <div class="col-md-2">
                      <button
                        class="btn btn-default"
                        style="margin-top: 0"
                        v-on:click="r.broker = null;r.tmp_broker = null;">
                        <i class="nc-icon nc-minimal-left"></i>
                      </button>
                    </div>
                    <div class="col-md-10">
                      <fg-input
                        v-model="r.tmp_broker"
                        type="text"
                        :placeholder="`${$t('helper_accbroker')}`">
                      </fg-input>
                    </div>
                  </div>
                  <div v-else>
                    <el-select
                      class="form-control"
                      v-model="r.broker"
                      :placeholder="`${$t('helper_accbroker')}`"
                      style="margin-bottom: 10px;border: 0; padding: 0;"
                      v-if="(r.broker === null || r.broker !== 'Other')"
                      @change="filterPlatform(r.broker, r)">

                      <el-option
                        class="select-default"
                        v-for="(broker, index) in brokerlist"
                        :key="index"
                        :value="broker.name">
                        {{broker.name}}
                      </el-option>
                    </el-select>
                  </div>
                </td>
                <td colspan="4" class="mobile-fix">
                  <label>{{ platformtxt }}</label>
                  <select
                    v-model="r.platform"
                    class="form-control"
                    :placeholder="platformtxt"
                    @change="onSetPlatform(r)"
                    style="margin-bottom: 10px; padding: 0;">
                    <option class="select-default"
                               v-for="(item, index) in r.available_platform"
                               :value="item.prop"
                               :key="index">
                      {{item.label}}
                    </option>
                  </select>
                </td>


                <td class="mobile-fix">
                  <label>{{$t('helper_accserver')}}</label>
                  <div v-if="(r.server == 'Other' || r.broker == 'Other')" class="row">
                    <div class="col-md-2">
                      <button
                        class="btn btn-default"
                        style="margin-top: 0"
                        v-on:click="r.server = null;r.tmp_server = null;">
                        <i class="nc-icon nc-minimal-left"></i>
                      </button>
                    </div>
                    <div class="col-md-10">
                      <fg-input
                        v-model="r.tmp_server"
                        type="text"
                        :placeholder="`${$t('helper_accserver')}`">
                      </fg-input>
                    </div>
                  </div>
                  <div v-else>
                    <el-select
                      class="form-control"
                      v-model="r.server"
                      :placeholder="`${$t('helper_accserver')}`"
                      style="margin-bottom: 10px;border: 0; padding: 0;"
                      :disabled="!r.platform || !r.broker"
                      v-if="(r.broker === null || (r.broker !== 'Other' && r.server !== 'Other'))">
                      <el-option
                        class="select-default"

                        v-for="server in r.available_server"
                        :key="server.prop"
                        :label="getOtherLabel(server.server)"
                        :value="server.server">
                      </el-option>
                    </el-select>
                  </div>
                </td>
                <td class="mobile-fix">
                  <label>{{ $t('helper_accentry') }}</label>
                  <fg-input
                    v-model="r.account"
                    type="text"
                    replace="[^0-9]"
                    :disabled="!r.platform"
                    :placeholder="`${$t('helper_accentry')}`">
                  </fg-input>
                </td>
                <td class="mobile-fix">
                  <label>{{ $t('helper_accpwd') }}</labeL>
                  <fg-input
                    :disabled="!r.platform"
                    v-model="r.pwd"
                    type="password"
                    :placeholder="`${$t('helper_accpwd')}`">
                  </fg-input>
                </td>
              </tr>
            </table>
            <hr>
            <collapse v-if="not_included_rows.length > 0" id="not_included" >
              <collapse-item :title="notImportedTxt" name="1" leftIcon="fa fa-exclamation-triangle">
                <div v-for="i in not_included_rows">
                  <i class="fa fa-exclamation-triangle text-danger"></i>
                    <span> {{ $t('account') }}: {{i.account}}: {{ $t('near_line') }}: {{i.comment}}</span>
                </div>
                <div class="col-md-12 text-right">
                  <p-button v-on:click="clearNotIncluded"> ok </p-button>
                </div>
              </collapse-item>
            </collapse>

          </div>
          <div class="card-footer mb-3">
            <div class="row">
              <div class="col-md-6">
                <p-button type="info" v-on:click="addRow">
                  <i class="nc-icon nc-simple-add"></i>
                  <span v-if="Constants.methods.isMobile()">{{ $t('add') }}</span>
                  <span v-else>{{ $t('addrow') }}</span>
                </p-button>
                <p-button class="ml-3" v-if="!Constants.methods.isMobile()" type="danger" v-on:click="openCsvModal()">
                  <i slot="label" class="fa fa-plus"></i>
                  {{ $t('import_csv') }}
                </p-button>
              </div>
              <div class="col-md-6 text-right">
                <p-button type="success" v-on:click="addAll">
                  <i class="nc-icon nc-check-2"></i>
                  {{ $t('create') }}
                </p-button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </module>
</template>
<script>
import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import {Button, Modal, Collapse, CollapseItem} from 'src/components/UIComponents'
import swal from 'sweetalert2'
import Constants from "@/assets/constants";
import {i18n} from "@/assets/i18n-plugin";

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(require('vue-moment'))

const user_data = JSON.parse(localStorage.getItem('user-info'));

export default {
  components: {
    [Button.name]: Button, Modal, Collapse, CollapseItem
  },
  computed: {
    Constants() {
      return Constants
    }

  },
  data () {
    return {
      inprogress: true,
      alert_me: false,
      alert_msg: false,
      alert_class: 'text-danger',
      platformtxt: this.$t('platformtxt'),
      notImportedTxt: this.$t('not_imported_txt'),
      rows: [
        {
          account: null,
          broker: null,
          server: null,
          pwd: null,
          tmp_broker: null,
          tmp_server: null,
          remove: false,
          platform: null,
          available_platform: [],
          available_server: [],
        }
      ],
      not_included_rows: [],
      servers: [],
      brokerlist: [],
      isadmin: false,
      ueml: null,
      failedadding: false,
      inprogresstxt: this.$t('loadingtxt') +" "+ this.$t('multipleaccountadd') +". "+ this.$t('pleasewait'),
      platformList: [
        {
          prop: 'mt4',
          label: 'MT4'
        },
        {
          prop: 'mt5',
          label: 'MT5'
        }
      ],
      modal_csv: false,
      modal_csv_inprogress: false
    }
  },
  methods: {
    _addAccount(idx) {
      if (idx < this.rows.length) {
        const _proceed = () => {
          // Remove succesful from entry
          this.rows.splice(idx, 1);
          // Next one
          this._addAccount(idx);
        };
        const _failadd = () => {
          this.failedadding = true;
          // Next one
          this._addAccount(idx+1);
        };
        const acc = this.rows[idx];

        let json_data = {
          platform: acc.platform,
          broker: acc.broker,
          server: acc.server,
          centaccount: false,
          password: acc.pwd,
        };

        if (json_data.broker == 'Other' || json_data.server == 'Other') {
          json_data.server = acc.tmp_server
        }

        if (json_data.broker == 'Other') {
          json_data.broker = acc.tmp_broker
        }

        this.$addAccount_v2({
          eml: this.ueml,
          account: acc.account,
          newacc: true,
          json_data: json_data
        }).then(_proceed, _failadd);

      } else {
        this.inprogress = false;
        // IF Failed Adding Accounts
        if (this.failedadding) {
          this.$toast.error(this.$t('failaddingaccount'));
          this.resetRows()
        } else { // Success adding all accounts
          localStorage.removeItem('hc_accounts-'+ this.ueml);
          this.resetRows();
          swal({
            title: this.$t('successtxt'),
            text: this.$t('multipleaccountadd'),
            type: 'success',
            allowOutsideClick: false,
            allowEscapeKey: false
            }).then(this.changeLocation)
        }
      }
    },
    changeLocation(){
      location.href = this.isadmin ? "/admin_accounts/" + this.ueml : "/accounts/main";
    },
    addAll() {
      if (this.notAllFieldsPopulated()) {
        this.$toast.warning(this.$t('populatefieldbelow'));
        this.alert_class = 'text-danger';
        return;
      }
      this.inprogresstxt = this.$t('addingaccounts');
      this.inprogress = true;
      this._addAccount(0);
    },
    removeRow(idx) {
      this.rows.splice(idx, 1);
    },
    notAllFieldsPopulated() {
      let allpop = false;
      let i=0
      for (i;i < this.rows.length;i++) {
        if (!this.rows[i].account) {
          return true;
        }
        if (!this.rows[i].account.match(/^[0-9]+$/)) {
          return true;
        }
        if (this.rows[i].broker === 'Other') {
          if (!this.rows[i].tmp_broker) {
            return true;
          }
          if (!this.rows[i].tmp_server) {
            return true;
          }
        } else {
          if (!this.rows[i].server) {
            return true;
          }
          if (!this.rows[i].broker) {
            return true;
          }
        }
        if (this.rows[i].server === 'Other' && !this.rows[i].tmp_server) {
          return true;
        }
        if (!this.rows[i].pwd) {
          return true;
        }
      }
      this.rows[i-1].remove = true;
      return allpop;
    },
    clearNotIncluded() {
      this.not_included_rows = []
    },
    addRow() {
      if (this.notAllFieldsPopulated()) {
        this.$toast.warning(this.$t('populatefieldbelow'));
        return;
      }
      this.rows.push({
        account: null,
        broker: null,
        server: null,
        pwd: null,
        tmp_broker: null,
        tmp_server: null,
        remove: false,
        platform: null,
        available_platform: [],
        available_server: [],
      });
    },
    resetRows() {
      this.rows = [
        {
          account: null,
          broker: null,
          server: null,
          pwd: null,
          tmp_broker: null,
          tmp_server: null,
          remove: false,
          platform: null,
          available_platform: [],
          available_server: [],
        }
      ]
    },
    prevPage() {
      window.history.back();
    },
    startDownload() {
      let request = new XMLHttpRequest();
      const stateChanged = () => {
        if(request.readyState == 2 && request.status == 200) {
          //console.log('Download Started');
        }
        else if(request.readyState == 3) {
          //console.log("In Progress");
        }
        else if(request.readyState == 4) {
          //console.log("Download Done");
          window.open('/static/docs/model.csv', '_top');
          //this.dlall = true;
        }
      };

      request.addEventListener('readystatechange', stateChanged);

      request.addEventListener('progress', function(e) {
        //console.log(percent_complete);
      });

      request.responseType = 'blob';

      // Downloading Doc
      request.open('get', '/static/docs/model.csv');
      request.send();
    },
    importCSV() {
      this.$refs.fileInput.click()
    },
    openCsvModal() {
      this.modal_csv = true
    },
    fileHandler() {
      this.modal_csv_inprogress = true
      let files = event.target.files[0]
      let reader = new FileReader();
      reader.readAsText(files);
      reader.addEventListener(
        "load", () => {
          let lines = reader.result.split('\n');
          let headers = lines[0].replace(/'/g, '').split(',');
          if(!headers.includes('broker_name','platform','broker_server_name','account_number','account_password')) {
            return this.$toast.warning(this.$t('csv_file_error'));
          }
          let data = lines.slice(1).map(line => {
              let values = line.replace(/'/g, '').split(',');
              return headers.reduce((obj, header, index) => {
                  obj[header] = values[index];
                  return obj;
              }, {});
          });
          data = data.map( obj => {
            if(obj.platform) {
              return {
                ...obj,
                platform: obj.platform.toLowerCase()
              }
              return obj
            }
          })

          this.rows = []
          for(let j=0;j < data.length;j++) {
            if(data[j]){
              let foundBroker = this.brokerlist.find( el =>
                el.name.toLowerCase() == data[j].broker_name.toLowerCase() && (
                el.platform == data[j].platform || el.platform === 'both')
              )
              if(foundBroker) {
                this.rows.push({
                  account: data[j].account_number,
                  broker: foundBroker.name,
                  server: this.setServer(data[j], foundBroker.name),
                  pwd: data[j].account_password,
                  tmp_broker: null,
                  tmp_server: null,
                  remove: false,
                  platform: data[j].platform,
                  available_platform: this.setAvailablePlatform(foundBroker),
                  available_server: this.setAvailableServer(data[j], foundBroker.name),
              });
              } else {
                this.not_included_rows.push({
                  account: data[j].account_number,
                  broker: data[j].broker_name,
                  server: data[j].broker_server_name,
                  pwd: data[j].account_password,
                  tmp_broker: null,
                  tmp_server: null,
                  remove: false,
                  platform: data[j].platform,
                  comment: j+2
                })
              }

            }
          }
        },
        false,
      )
      event.target.value = "";
      this.modal_csv_inprogress = false
      this.modal_csv = false
    },
    toLowerCase(text){
      let temp = String(text)
      return temp.toLowerCase()
    },
    loadServers(resp) {
      if (!resp.success) {
        return;
      }

      this.servers = resp.data;

      for(let j=0;j < resp.data.length;j++) {
        const b = resp.data[j].broker;

        if (this.servers[b] === undefined) {
          this.servers[b] = [];
        }
        this.servers[b].push({
          'prop': resp.data[j].server,
          'label': resp.data[j].server
        });
      }

      this.inprogress = false;
    },
    failop (error) {
      if (error.data && error.data.msg) {
        error = error.data.msg;
      }
      this.$toast.error(error);
    },
    onSetPlatform(row) {
      row.available_server = []
      row.server = []
      let servers = this.servers.filter(server => {
        return server.broker == row.broker && server.platform == row.platform;
      });
      if(servers.length == 1) {
        // row.server = servers[0].server
      }
      return row.available_server = servers
    },
    setServer(row, broker_name) {
     let server_list = this.servers.filter(server => {
        return server.broker == broker_name && server.platform == row.platform;
      });
      let same_server = server_list.filter( el => {
        return row.broker_server_name == el.server
      });
      if(same_server.length === 0) {
        this.$toast.info(this.$t('server_for_account_x_not_found',[row.account_number]));
        return ''
      } else {
        return row.broker_server_name
      }
    },
    setAvailableServer(row, broker_name) {
      row.available_server = []
      let server_list = this.servers.filter(server => {
        return server.broker == broker_name && server.platform == row.platform;
      });

      return row = server_list
    },
    // filterServers(row, item) {
    //   let servers = this.servers.filter(server => {
    //     return server.broker == row.broker && server.platform == row.platform;
    //   });
    //
    //   return servers;
    // },
    getOtherLabel(label) {
      if (label == 'Other') {
        return this.$t('othertxt');
      }
      return label;
    },
    setAvailablePlatform(foundBroker) {
      const platforms = {
        mt4: { prop: 'mt4', label: 'MT4' },
        mt5: { prop: 'mt5', label: 'MT5' }
      };

      if (foundBroker.platform === 'both') {
        return [platforms.mt4, platforms.mt5];
      }

      return foundBroker.platform in platforms ? [platforms[foundBroker.platform]] : [];
    },
    filterPlatform(brokerSelected, item) {
      item.available_server = []
      item.available_platform = []
      item.server = ''
      item.platform = ''
      let broker_item = this.brokerlist.filter(el => el.name == brokerSelected)
      if (broker_item[0].platform !== 'both') {
        // item.platform = broker_item[0].platform
      }
      if (broker_item[0].platform == 'both') {
        return item.available_platform.push(
            {
              prop: 'mt4',
              label: 'MT4'
            },
            {
              prop: 'mt5',
              label: 'MT5'
            }
        )
      }
      if (broker_item[0].platform == 'mt4') {
        return item.available_platform.push({
          prop: 'mt4',
          label: 'MT4'
        })
      }
      if (broker_item[0].platform == 'mt5') {
        return item.available_platform.push({
          prop: 'mt5',
          label: 'MT5'
        })
      }
    },
    loadBrokers(resp){
      this.brokerlist = resp.data;
      this.brokerlist.push({
        'name': "Other",
        'platform': "both"
      });
    },
  },
  mounted () {
    this.isadmin = user_data.roles.indexOf('admin') >= 0;

    this.ueml = this.$route.params.userid ? this.$route.params.userid : user_data.email;

    this.$getServers().then(this.loadServers, this.failop);
    this.$getBrokerPlatform().then(this.loadBrokers, this.failop)
  },
  watch: {
    "not_included_rows": function () {
      if(this.not_included_rows.length > 0){
        this.$toast.warning(this.$t('some_accounts_was_not_imported'));
      }
    },
    "rows": function () {
      if(this.rows.length == 0) {
        this.resetRows()
      }
    }
  },
  created(){
    this.$setTranslatingInProgress()
  },
  beforeDestroy(){
    this.$root.$off("showTranslatingProgress")
  }
}
</script>
<style scoped lang="scss">
@import './src/assets/sass/paper/variables';
.el-input__inner::placeholder {
  color: black!important;
}
@media (max-width: 768px) {
  .mobile-fix {
    width: 100% !important;
    display: block !important;
  }
}
#not_included::v-deep {
  a {
    color: $font-color;
  }
  i {
    color: $font-color;
  }
  .card-header {
    background-color: #fafaf9;

  }
}
</style>
